import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getRemoteConfig } from 'firebase/remote-config';
import { logToCrashalytics, recordErrorInCrashalytics } from 'tatvarnsdk/Web';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
// import { showToast } from '@modules/common/index';

const firebaseConfig = require('./firebase-config.json').result.sdkConfig;
export const firebaseApp = initializeApp(firebaseConfig);
export const remoteConfig = getRemoteConfig(firebaseApp);
export const functions = getFunctions(firebaseApp, 'europe-west2');

const USE_GAE_EMULATOR = false;
const appEngineMethods = [
  'generateQrForWeb',
  'queryInDb',
  'getClassesWithCategoriesWeb',
  'getPlansSubscriptionsAndCardDetails',
  'deleteACard',
  'addACard',
  'setDefaultPayment',
  'retryFailedPayment',
  'registerZoom',
  'applyStripeCoupon',
  'purchaseSubscriptionOrClassPass',
  'deleteUserAccount',
  'deleteDocWithUsedToken',
  'verifyEmail'
];

const getBaseApiUrl = () => {
  const appName = window.location.href;
  if (appName.includes('localhost')) {
    return 'https://tatva-app-dev2.nw.r.appspot.com/user';
  }
  if (appName.includes('dev')) {
    return 'https://tatva-app-dev2.nw.r.appspot.com/user';
  }
  if (appName.includes('uat')) {
    return 'https://tatva-app-uat2.nw.r.appspot.com/user';
  }
  return 'https://tatva-app-prd2.nw.r.appspot.com/user';
};

export const triggerCloudFunction = async (methodName, data) => {
  logToCrashalytics(`Backend Function => ${methodName}`);
  try {
    // console.log('Parameters:', data);
    const APP_ENGINE_SIMULATOR_URL = 'http://localhost:4000/user';
    const HOSTED_URL = getBaseApiUrl();
    let idToken = (await getAuth().currentUser?.getIdToken(true)) ?? null;

    const URL = USE_GAE_EMULATOR ? APP_ENGINE_SIMULATOR_URL : HOSTED_URL;
    // console.log(`${URL}/${methodName}`);
    const response = await axios.post(`${URL}/${methodName}`, data, {
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (response && response.status === 401) {
      logToCrashalytics('Unauthorised');
      getAuth().signOut();
      // showToast({
      //   titleId: 'Login Again',
      //   message: 'You are logged out, please login again.',
      //   notificationType: 2
      // });
      // reroute to login screen
      return null;
    }
    const res = response.data;
    // console.log('Result', res);
    return res;
  } catch (e) {
    recordErrorInCrashalytics(`Error in calling cloudfunction ${methodName}`, e);
    return null;
  }
};
