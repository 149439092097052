import React, { useEffect, useState } from 'react';
import { firebaseApp } from '../../firebase';
import { applyActionCode, getAuth, checkActionCode } from 'firebase/auth';
import { HeaderContainer, MainContainer, TextStyle } from './EmailVerification.styles';
import { HeaderWithLogo, Translation } from '../common';
import { logToCrashalytics, emailVerified } from 'tatvarnsdk/Web';

function EmailVerification() {
  const [message, setMessage] = useState('verifying');
  const data = window.location;

  useEffect(() => {
    logToCrashalytics('Email Container');
    handleVerifyEmail();
  }, []);

  const handleVerifyEmail = async () => {
    const auth = getAuth(firebaseApp);
    const queryMap = new Map();

    if (data.search) {
      data.search.split('&').forEach((element) => {
        if (element) {
          const [key, value] = element.split('=');
          queryMap.set(key, value);
        }
      });
    }

    if (queryMap.get('?mode') === 'verifyEmail') {
      try {
        const obbCode = queryMap.get('oobCode');
        const data = await checkActionCode(auth, obbCode);
        const email = data?.data?.email;
        await applyActionCode(auth, obbCode)
          .then(async (res) => {
            console.log(res);
            setMessage('email_verify');
          })
          .catch((e) => {
            setMessage('verification_failed');
            console.error(e);
          });
        if (email) {
          await emailVerified(email);
        }
      } catch (error) {
        setMessage('verification_failed');
        console.error(error);
      }
    } else {
      setMessage('nothing_verify');
    }
  };

  return (
    <MainContainer>
      <div>
        <HeaderContainer>
          <HeaderWithLogo />
        </HeaderContainer>
      </div>
      <TextStyle>
        <h1>
          <Translation id={message} />
        </h1>
      </TextStyle>
    </MainContainer>
  );
}

export default EmailVerification;
