import React, { memo, useEffect, useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { AiOutlineDownload } from 'react-icons/ai';
import {
  PaymentScreenContainer,
  Row1,
  CancelWrapper,
  HeaderWrapper,
  Container,
  SubscriptionWrapper,
  SubscriptionText,
  ScheduleButtons,
  StickerWrapper,
  Row2,
  HomeButton,
  AppBtnPrimary,
  ContainerIllustrationWrapper
} from './PaymentSuccess.styles';
import { HeaderWithLogo, SubscriptionCardWrapper, Translation, getPlanName } from '../common/index';
import { CancellationPolicy } from './components/CancellationPolicy/CancellationPolicy';
import { Illustrations } from './components/Illustrations/Illustrations';
import { routeConfig } from '@app/routeConfig';
import { logToCrashalytics, isClassPass } from 'tatvarnsdk/Web';

export default function PaymentSuccess(props) {
  const history = useHistory();
  const currentUser = getAuth().currentUser;
  const state = props.history.location.state;
  if (!state) {
    return <Redirect to={routeConfig.dashboard.route} />;
  }
  const { success, planDetails, error, userProfile, userSubscription, disciplineObj } = state;
  const planData = {
    plan: planDetails,
    disciplineObj: disciplineObj,
    userProfile: userProfile
  };
  const user = currentUser.displayName;
  const plan_name = getPlanName(planDetails).toUpperCase();
  const onHomeClicked = () => {
    history.push(routeConfig.dashboard.route);
  };
  const [os, setOs] = useState();
  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || window.opera;
    if (/android/i.test(userAgent)) {
      setOs('Android');
      return;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setOs('IOS');
      return;
    }

    setOs('Unknown Device');
    return;
  };
  const redirectOnClick = () => {
    let appBaseLink = 'tatva.app://'; // same for IOS prd and android
    if (
      window.location.host.includes('dev') ||
      window.location.host.includes('localhost') ||
      window.location.host.includes('192') //LAN
    ) {
      appBaseLink = 'tatva.app.dev://';
    } else if (window.location.host.includes('uat')) {
      appBaseLink = 'tatva.app.uat://';
    }
    const endpoint = `classDetails?id=${props.selectedClassId}`;
    if (props.selectedClassId) {
      window.location.replace(`${appBaseLink}${endpoint}`);
    } else {
      window.location.replace(appBaseLink);
    }
  };
  useEffect(() => {
    getMobileOperatingSystem();
    logToCrashalytics('Payment Success Container');
  }, []);
  return (
    <PaymentScreenContainer>
      <HeaderWrapper>
        <HeaderWithLogo />
      </HeaderWrapper>
      <ContainerIllustrationWrapper>
        <Container>
          <SubscriptionText>
            <div>
              <Translation id="congratulations" />
              <b>
                <Translation text={user} />!
              </b>
            </div>
            <div>
              {isClassPass(planDetails) ? (
                <>
                  <Translation id="YOU_HAVE" /> {userProfile.classPassCount + 1} <Translation id="CLASS" />
                  {userProfile.classPassCount + 1 > 1 ? <Translation text="passes" /> : <Translation text="pass" />}
                </>
              ) : null}
            </div>
          </SubscriptionText>
          <Row1>
            <SubscriptionWrapper>
              <SubscriptionCardWrapper plan={planData} type="PAYMENT_SUCCESS" />
            </SubscriptionWrapper>
            <ScheduleButtons>
              {/* <ScheduleClass textId="schedule_class" /> */}
              {os === 'IOS' ? <AppBtnPrimary textId="go_to_app" onClick={redirectOnClick} /> : ''}
              {os === 'IOS' ? null : <HomeButton textId="go_to_home" onClick={onHomeClicked} />}
              {/* <DownloadWrapper>
                <DownloadButtonWrapper>
                  <AiOutlineDownload />
                </DownloadButtonWrapper>
                <Translation id="download_receipt" />
              </DownloadWrapper> */}
            </ScheduleButtons>
          </Row1>
        </Container>
        <StickerWrapper>
          <Illustrations height="90%" width="90%" />
        </StickerWrapper>
      </ContainerIllustrationWrapper>
      <Row2>
        <CancelWrapper>
          <CancellationPolicy />
        </CancelWrapper>
      </Row2>
    </PaymentScreenContainer>
  );
}
